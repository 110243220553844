<route>
{
  "meta": {
    "permission": [
      "invoices.view_paymenttype"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <dialpaymenttype
        v-model="dialog"
        :to-edit="toEdit"
        @done="reload = true"
      />
      <i-table
        :title="$tc('paymenttype', 2)"
        :headers="headers"
        app="invoices.paymenttype"
        api="invoice.type"
        :reload.sync="reload"
        @click:create="open()"
        @click:edit="open($event)"
      >
      </i-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialpaymenttype from '../../components/paymenttype/modal.vue'
export default {
  components: {
    dialpaymenttype
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text:
            this.paymentTypeBtn.delete || this.paymentTypeBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.paymentTypeBtn.delete || this.paymentTypeBtn.edit
              ? 'actions'
              : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    paymentTypeBtn() {
      return {
        delete:
          this.getPermissions([`invoices.delete_paymenttype`]) || this.isAdmin,
        edit:
          this.getPermissions([`invoices.change_paymenttype`]) || this.isAdmin
      }
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    searchSold(searchSold) {
      this.search = searchSold
      this.getPaymentType()
    },
    // verificar si es necesario
    async getPaymentType() {
      this.loading = true
      try {
        let response = await this.$api.invoice.type.list({})
        this.payments = response.data.results
        this.total = response.data.count
      } finally {
        if (this.$store.getters.getIsloading) {
          this.$store.dispatch('actionLoading')
        }
        this.loading = false
      }
    }
  },
  mounted() {
    this.getPaymentType()
  }
}
</script>
